<!-- 操作记录 -->
<template>
  <div>
    <div class="mainBody">
  
      <!--搜索框-->
      <div class="filterBarOper">
        <!--操作人-->
        <div>
          <label for="operAdmin">{{ $t('OperAdmin') }}：</label>
          <input type="text" id="operAdmin" v-model.trim="userName">
        </div>
        <!--Email-->
        <div>
          <label for="email">Email：</label>
          <input id="email" v-model.trim="email">
        </div>
        <!--联系电话-->
        <div>
          <label for="mobile">{{ $t('ContactNum') }}：</label>
          <input type="number" id="mobile" v-model.number.trim="mobile">
        </div>
        <!--操作类型-->
        <div>
          <label>{{ $t('OperType') }}：</label>
          <el-select v-model="operType" :placeholder="$t('OperType')">
            <el-option
              v-for="item in operationTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!--申请时间-->
        <div>
          <label>{{ $t('OperDate') }}：</label>
          <el-date-picker
            v-model="createTime"
            type="date"
            :placeholder="$t('ChooseDate')"
            value-format="yyyy-MM-dd"
            style="float: left;">
          </el-date-picker>
        </div>
        <el-button @click="searchOperData">{{ $t('Search') }}</el-button>
      </div>
      
      <!--表格-->
      <el-table
        :data="operList"
        border
        style="width: 99%;margin: 0 auto">
        <!--操作ID-->
        <el-table-column
          fixed
          width="180"
          prop="operationId"
          :label="$t('Operation')+'id'">
        </el-table-column>
        <!--操作内容-->
        <el-table-column
          prop="content"
          :label="$t('OperContent')">
        </el-table-column>
        <!--操作类型-->
        <el-table-column
          prop="type"
          width="150"
          :label="$t('OperType')">
          <template slot-scope="scope">{{ numToType(scope.row.type) }}</template>
        </el-table-column>
        <!--操作人-->
        <el-table-column
          prop="adminName"
          width="180"
          :label="$t('OperAdmin')">
        </el-table-column>
        <!--Email-->
        <el-table-column
          prop="email"
          width="150"
          label="Email">
        </el-table-column>
        <!--联系电话-->
        <el-table-column
          prop="mobile"
          width="150"
          :label="$t('ContactNum')">
        </el-table-column>
        <!--操作日期-->
        <el-table-column
          prop="operationDate"
          width="160"
          :label="$t('OperDate')">
        </el-table-column>
      </el-table>
      
      <!--分页-->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="operTotal"
        style="margin: 10px auto 10px 20px">
      </el-pagination>
    </div>
  </div>
</template>


<script>
import api from "@/fetch/api";

import mixin from "@/assets/js/mixin";

export default {
  mixins: [mixin],
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      operList: [],
      chamberId: data.chamberId,
      adminId: data.id,
      operTotal: 0,
      pageSize: 10,
      currentPage: 1,
      userName: "",
      adminName: "",
      mobile: "",
      address: "",
      operType: "3",
      createTime: "",
      formLabelWidth: "100px",
      email:'',
      
      operationTypes: [{
        value: "3",
        label: this.$t("AllData")
      }, {
        value: "0",
        label: this.$t("DeleteData")
      }, {
        value: "1",
        label: this.$t("Add")
      }, {
        value: "2",
        label: this.$t("Edit")
      }],
      
      initOper: {
        chamberId: data.chamberId,
        adminId: data.id,
        pageNum: 1,
        currentAdmin: data.id
      },
      
    };
  },
  watch: {
    uploadDataForm: {
      deep: true,
      handler(val) {
        console.log("Watch fileList", val.fileList);
      }
    }
  },
  mounted() {
    this.initOperationData(1);
    
    
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    active1() {
      // console.log("全部", this.initOper);
      this.activeUser = 1;
      delete this.initOper.status;
      this.initOperationData(1);
    },
    active2() {
      // console.log("启动", this.initOper);
      this.activeUser = 2;
      this.initOper.status = 1;
      this.initOperationData(1);
    },
    active3() {
      // console.log("禁用", this.initOper);
      this.activeUser = 3;
      this.initOper.status = 2;
      this.initOperationData(1);
    },

//            初始化用户列表
    initOperationData(currentPage) {
      this.initOper.tenantCode = JSON.parse(sessionStorage.getItem('cacheChamber')).adminData.tenantCode
      this.initOper.pageNum = currentPage;
      this.initOper.pageSize = this.pageSize;
      this.initOper.language = this.language;
      this.userName ? this.initOper.userName = this.userName : delete this.initOper.userName;
      this.adminName ? this.initOper.adminName = this.adminName : delete this.initOper.adminName;
      this.mobile ? this.initOper.mobile = this.mobile : delete this.initOper.mobile;
      this.email ? this.initOper.email = this.email : delete this.initOper.email;
      this.operType !== "3" ? this.initOper.type = this.operType : delete this.initOper.type;
      this.createTime ? this.initOper.createTime = this.createTime : delete this.initOper.createTime;
      api.getOperation(this.initOper).then(res => {
        this.operList = res.data.records;
        this.operTotal = res.data.total;
      });
    },

//            搜索用户列表
    searchOperData() {
      this.userName ? this.initOper.userName = this.userName : delete this.initOper.userName;
      this.adminName ? this.initOper.adminName = this.adminName : delete this.initOper.adminName;
      this.mobile ? this.initOper.mobile = this.mobile : delete this.initOper.mobile;
      this.email ? this.initOper.email = this.email : delete this.initOper.email;
      this.operType !== "3" ? this.initOper.type = this.operType : delete this.initOper.type;
      this.createTime ? this.initOper.createTime = this.createTime : delete this.initOper.createTime;
      // console.log("searchOper", this.initOper);
      this.initOperationData(1);
    },

//            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initOperationData(currentPage);
    }
    
  }
};
</script>
<style lang="scss" scoped>
.mainBody {
  width: 1600px;
  margin-top: 5px;
}

.filterBarOper {
  width: 99%;
  height: 130px;
  margin: 5px auto 10px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  background-color: #677D95;
  border-radius: 5px;
  color: white;
  padding-top: 10px;
  /*line-height: 65px;*/
  input {
    width: 200px;
    height: 30px;
    border: 1px solid white;
    vertical-align: middle;
  }
  
  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
    width: fit-content;
  }
  
  .el-button {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 4;
    grid-column-end: 5;
    /*margin: 4px 10px 0 120px;*/
    margin: auto;
    width: 200px;
    height: 50px;
    line-height: 0.4;
    /*line-height: 50px;*/
    background-color: #677D95;
    color: white;
    
    &:hover {
      background-color: #9FBEE6 !important;
    }
  }
}

.navigatorAdmin {
  /*width: 1660px;*/
  width: 98.9%;
  height: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /*border:1px solid black;*/
  margin: 10px auto;
  
  & > span {
    padding-top: 3px;
    text-align: center;
    border: 1px solid black;
  }
}

.el-table th {
  /*background-color: #677D95;*/
  /*background-color: #A6C3E3;*/
  background-color: #CCCCCC;
  color: black;
  opacity: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
